<template>
  <div> 
    <div> 
        <main class="page-content">
        <div class="account-page-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <ul class="nav myaccount-tab-trigger" id="account-page-tab" role="tablist">
                  <li class="nav-item">
                      <a class="nav-link active" id="account-dashboard-tab" data-toggle="tab" href="#account-dashboard" role="tab" aria-controls="account-dashboard" aria-selected="true"><i class="fas fa-user-alt"></i> My Profile</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="my-bids-tab" data-toggle="tab" href="#my-bids" role="tab" aria-controls="my-bids" aria-selected="false"><i class="fas fa-gavel"></i> My Bids</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="my-winning-tab" data-toggle="tab" href="#my-winning" role="tab" aria-controls="my-winning" aria-selected="false"><i class="fas fa-trophy"></i> My Winnings</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="account-orders-tab" data-toggle="tab" href="#account-orders" role="tab" aria-controls="account-orders" aria-selected="false"><i class="fas fa-microchip"></i> Processing Wins</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" id="account-packages-tab" data-toggle="tab" href="#account-packages" role="tab" aria-controls="account-packages" aria-selected="false"><i class="fas fa-cubes"></i> Packages</a>
                  </li>
                  <!-- <li class="nav-item">
                      <a class="nav-link" id="account-details-tab" data-toggle="tab" href="" role="tab" aria-controls="account-details" aria-selected="false"><i class="fas fa-money-bill"></i> Buy Packages</a>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link" id="my-password-tab" data-toggle="tab" href="#my-password" role="tab" aria-controls="my-password" aria-selected="false"><i class="fas fa-key"></i> Change Password</a>
                  </li>                  
                </ul>
                <div class="">
                  <div class="myaacountbottompos">
                    <ul
                      class="nav myaccount-tab-trigger"
                      id="account-page-tab"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="account-orders-tab"
                          data-toggle="tab"
                          href="#account-orders"
                          role="tab"
                          aria-controls="account-orders"
                          aria-selected="false"
                          ><i class="fas fa-headphones-alt"></i> Help</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="account-address-tab"
                          data-toggle="tab"
                          href="#account-address"
                          role="tab"
                          aria-controls="account-address"
                          aria-selected="false"
                          ><i class="fas fa-cogs"></i> Settings</a
                        >
                      </li>
                      <div v-if="currentUser" class="navbar-nav ml-auto">  
                          <li class="nav-item">
                             <a class="nav-link" href @click.prevent="logOut"> <i class="fas fa-user-circle"></i>LogOut
                              </a>
                           </li>
                      </div>                      
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="tab-content myaccount-tab-content" id="account-page-tab-content">
                    <div class="tab-pane fade show active" id="account-dashboard" role="tabpanel" aria-labelledby="account-dashboard-tab">
                        <div class="" style="margin-bottom:18px;">                            
                 <div class="l-section">
                              <h5 class="c-section__heading">Details</h5>
                              <div class="formzzz" >
                                <div v-if="!currentuserinfo"  >
                                    
                                </div>
                                <div class="row" v-else>
                                  <div class="col-md-6">
                                    <div>
                                      <label class="labelzzz">Name</label>
                                      <input type="text" name="name" id="custName" v-model="currentuserinfo.custName"  class="inputzzz">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div>
                                      <label class="labelzzz">Email</label>
                                      <input type="email" name="email" v-model="currentuserinfo.custEmail" class="inputzzz">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div>
                                      <label class="labelzzz">Phone</label>
                                      <input name="status" v-model="currentuserinfo.custPhone" class="inputzzz">
                                    </div>
                                 </div>
                                </div>
                                
                                <button type="submit" @click="updateuserinfo" class="buttonzzz">Update</button>
                               <p>{{ message }}</p>
                              </div>
                            </div>
                        </div>
                        <div class="row">
                            <h3 class="fontWeight500 paddingBottom12">Package</h3>
                                        <div class="upgradebidwrap">
                                           <div class="upgradebidleft">
                                               <a class="bidleftbtn">12/30 BIDS</a> REMAINING
                                           </div>
                                           <div class="upgradebidright">
                                               <a class="upgradenowbtn">Upgrade Now</a>
                                           </div>
                                        </div>
                                    </div>
                                    <div class=" marginTop20">
                                        <h3 class="fontWeight500 paddingBottom12">Wishlist</h3>
                                        <!-- <h3 class="fontWeight500 ">Wishlist</h3> -->
                                        <div class="row nom">
                                          <div>
                                            <wishList></wishList>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="account-orders" role="tabpanel" aria-labelledby="account-orders-tab">  
                                 <comingsoon></comingsoon>                                 
                                </div>
                                <div class="tab-pane fade" id="account-packages" role="tabpanel" aria-labelledby="account-packages-tab">
                                  <comingsoon></comingsoon>  
                                </div>
                                <div class="tab-pane fade" id="account-details" role="tabpanel" aria-labelledby="account-details-tab">
                                 <comingsoon></comingsoon>   
                                </div>
                                <div class="tab-pane fade" id="account-address" role="tabpanel" aria-labelledby="account-address-tab">
                                 <comingsoon></comingsoon>   
                                </div>
                               
                                <div class="tab-pane fade" id="my-bids" role="tabpanel" aria-labelledby="my-bids-tab">
                                    <div>
                                             <mybidz></mybidz>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="my-winning" role="tabpanel" aria-labelledby="my-winning-tab">
                                    <div>
                                            <mywinnings></mywinnings>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="my-password" role="tabpanel" aria-labelledby="my-password-tab">
                                    <div>
                                          <changepassword></changepassword>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="process-winners" role="tabpanel" aria-labelledby="process-winners-tab">
                                    <div>
                                            <comingsoon></comingsoon>  
                                    </div>
                                </div>
                            </div>
                        </div> 
                  </div>
          </div>
        </div>
      </main>
    </div> 
  </div>
</template>
<script>
import userService from '../services/user.service';
import user from '../models/localstorage'
export default {
 
  name: 'MyAccount',
  data(){
return{
  currentuserinfo:'',
  //currentuserentry:'',
  message:'',
}
  },
   mounted() {
      
    if(this.$store.state.auth.user) { 
          this.st =user;
       this.getuserbyId();  
    }
    else 
    {     
      this.$router.push('/login').catch(()=>{"myaccount"});           
    } 
    
  },
  methods:{
      getuserbyId(){
         
         let id = this.st[0].customerId;
          
         userService.get(id)
        .then(response => {
          this.currentuserinfo = response.data.Data[0];          
        })
        .catch(e => {
          console.log(e);
        });
      },
      logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login').catch(()=>{"getuserbyid"});
    },
    updateuserinfo(){
       let id = this.$store.state.auth.user[0].customerId;

        userService.update(id ,this.currentuserinfo).then(response =>{
          console.log(response.data);
         // this.message =' updated successfully!'
           this.prifileinfo();
        })
    },
     pagerefresh(){
       window.location.reload();
     },
     prifileinfo(){
       if(!this.currentuserinfo){
         alert("hi")
        window.location.reload();
    }
     }

  },
  computed: {
    currentUser() {    
       return this.$store.state.auth.user;
    }
  },
 
 
};
</script>


